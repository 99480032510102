import styled from "styled-components";
import { device } from "./device";

export interface NavbarContainerProps {
  readonly inverted?: boolean;
  readonly headerFullscreen?: boolean;
  readonly lightBg?: boolean;
}

export interface BurgerMenuProps {
  readonly sidebarOpened?: boolean;
  readonly inverted?: boolean;
}

export interface HeaderProps {
  readonly headerAbsolute?: boolean;
  readonly headerFixed?: boolean;
}

export const Header = styled.header<HeaderProps>`
    max-width: 100vw;
    position: ${(props) => (props.headerAbsolute ? 'absolute' : props.headerFixed ? 'fixed' : 'relative')};
    top: 0;
    width: 100%;
    z-index: 100;
`;
export const NavbarContainerOuter = styled.div`
    grid-column: full;
    border-bottom: 1px solid #e8e9ea;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
`;
export const NavbarContainer = styled.div<NavbarContainerProps>`
    align-items: center;
    color: ${(props) => (props.inverted ? '#FFFFFF' : '#000000')};
    background-color: ${(props) => (props.lightBg ? '#FAFAFA' : '#FFFFFF')};
    display: flex;
    flex-direction: row;
    font-size: 22px;
    font-weight: 800;
    grid-column: full;
    height: 60px;
    letter-spacing: -0.03em;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 0;
    padding: .5em .5em;
    text-align: left;

    @media ${device.tablet} {
        padding: ${(props) => (props.headerFullscreen ? '.5em .5em' : '.5em 0em')};
        grid-column: ${(props) => (props.headerFullscreen ? 'full' : 'standard')};
    }
`;

export interface NavbarContainerSubmenuProps {
  readonly inverted?: boolean;
  readonly headerFullscreen?: boolean;
  readonly showIf?: boolean;
}
export const NavbarContainerSubmenu = styled.div<NavbarContainerSubmenuProps>`
    display: ${(props) => (props.showIf ? 'flex;' : 'none;')};
    position: absolute;
    top: 61px;
    left: 0;
    right: 0;
    background: #FFF;
    border-bottom: 1px solid #e8e9ea;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    flex-direction: row;
    align-items: center;
    color: ${(props) => (props.inverted ? '#FFFFFF' : '#000000')};
    font-size: 22px;
    font-weight: 800;
    grid-column: full;
    height: 45px;
    letter-spacing: -0.03em;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 0;
    padding: .5em 0em;
    justify-content: center;
    text-align: left;
    
/*     @media ${device.tablet} {
        grid-column: ${(props) => (props.headerFullscreen ? 'full' : 'narrow')};
    } */
`;

export interface NavbarContainerHideProps {
  readonly showIf: boolean;
}

export const NavbarContainerSubmenuHideContainer = styled.div<NavbarContainerHideProps>`
    display: ${(props) => (props.showIf ? 'flex;' : 'none;')};
`;

export const NavbarHeadline = styled.div`
    margin-left: auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: -0.03em;

    @media(max-width: 768px) {
      visibility: hidden;
      display: none;
    }
`;
export const MenuItem = styled.div<NavbarContainerProps>`
    color: ${(props) => (props.inverted ? props.theme.colors.fontWhite : props.theme.colors.fontBlack)};
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.05em;
    line-height: 20px;
    margin-bottom: 0;
    margin-right: 32px;
    margin-top: 0;
    text-align: left;
    display: flex;
    align-items: center;

    svg {
      margin-left: 2px;
      width: 18px;
      height: auto;
    }

    &:hover {
      color: ${(props) => props.theme.colors.primary};
      text-decoration: underline;
    }

    @media(max-width: 768px) {
      visibility: hidden;
      display: none;
    }

     @media(max-width: 1150px) {
       margin-right: 20px;
     }
`;
export const NavbarButtonContainer = styled.div`
   display: flex;
   margin-left: auto;
   position: relative;
   align-items: center;
   column-gap: 28px;
`;
export const NavbarDropdownContainer = styled.ul<NavbarContainerHideProps>`
    display: ${(props) => (props.showIf ? 'flex;' : 'none;')};
    box-shadow: 0 4px 8px rgb(118 118 120 / 20%);
    clip: ${(props) => (props.showIf ? 'unset' : 'rect(1px, 1px, 1px, 1px)')};
    height: ${(props) => (props.showIf ? 'auto' : '1px')};
    width: ${(props) => (props.showIf ? '300px' : '1px')};
    list-style: none;
    overflow: hidden;
    padding: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 20px;
    background: #FFFFFF;
    z-index: 10;
    border-radius: 0 0 12px 12px;

    span {
      user-select: none;
    }

    .navbar-dropdown-list-container {
      display: flex;
      flex-direction: column;

      a {
        color: ${(props) => props.theme.colors.fontBlack};
      }
    }

    li {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;

      a, div {
        cursor: pointer;
        align-items: center;
        display: flex;
        padding: 8px;
        letter-spacing: normal;
      }
      .svg-wrapper {
        padding: 0 16px 0 16px;
        svg {
          height: 30px;
          width: 30px;
        }

      }
      dl {
        margin: 0;
        padding: 8px 8px 8px 0px;

        dt {
          color: ${(props) => props.theme.colors.fontBlack};
          font-size: 14px;
          margin: 0;
          line-height: 20px;
          font-weight: 500;
          letter-spacing: normal;
        }
        dd {
          color: #717173;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          letter-spacing: normal;
        }
      }
    }
    li:hover {
      background-color: #f7f7f7;
    }
`;
export const NavbarButton = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    svg {
      margin-right: 6px;
    }

    @media(max-width: 768px) {
      visibility: hidden;
      display: none;
    }

    @media(max-width: 1150px) {
      span {
        visibility: hidden;
        display: none;
      }
    }
`;
export const NavbarButtonOuter = styled.div`
    margin-left: 16px;

    @media(max-width: 768px) {
      visibility: hidden;
      display: none;
    }
`;
export const NavbarButtonMobile = styled.div`
    margin-left: auto;

    @media(min-width: 768px) {
      visibility: hidden;
      display: none;
    }
`;
export const BurgerMenu = styled.div<BurgerMenuProps>`
    background-color: transparent;
    border          : none;
    cursor          : pointer;
    display:  none;
    height          : 34px;
    margin-left: auto;
    outline         : none;
    padding         : 0;
    visibility: hidden;
    width           : 34px;

    svg {
      height: 100%;
      width : 100%;
    }

  .line {
    fill        : none;
    stroke      : ${(props) => props.theme.colors.fontBlack};
    stroke-width: 6;
    transition  : stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width    : 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width    : 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width    : 6;
  }

  ${({ sidebarOpened }) => sidebarOpened && `
    .line1 {
    stroke-dasharray : 90 207;
    stroke-dashoffset: -134;
    stroke-width     : 6;
  }

  .line2 {
    stroke-dasharray : 1 60;
    stroke-dashoffset: -30;
    stroke-width     : 6;
  }

   .line3 {
    stroke-dasharray : 90 207;
    stroke-dashoffset: -134;
    stroke-width     : 6;
  }
  `}

    @media(max-width: 768px) {
      visibility: visible;
      display: flex;
    }
`;