import styled from 'styled-components';

interface LogoProps {
    readonly darkmode?: boolean;
}

export const Logo = styled.div<LogoProps>`
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 32px;
    margin-top: 0;
    a {
        display: flex;
        align-items: center;
    }

    svg {
        height: 57px;
        width: 57px;
    }
`;

export const TPLogo = styled.span<LogoProps>`
    cursor: pointer;
    line-height: 30px;
    margin-bottom: 0;
    margin-right: 32px;
    margin-top: 0;
    a {
        display: flex;
        align-items: center;
    }
`;
