import React from "react";
import styled from "styled-components";
import StyledLink from "../../../../../shared/components/global/StyledLink";
import { Button, ButtonSizes } from "../../../../../shared/styles/button";

interface MobileMenuContainerProps {
  open: boolean;
  className?: any;
  hideBorder?: boolean;
  borderTop?: boolean;
  noPadding?: boolean;
}

const MobileMenuContainer = styled.div<MobileMenuContainerProps>`
  height: ${(props) => (props.open ? "100%" : "0%")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.darkmodeBg};
  z-index: 10;
  transition: height 300ms ease;

  & > ul {
    ${(props) =>
      props.open &&
      `
        visibility: visible !important;
        transition-delay: 0s;
        `};
  }
`;

const MobileMenuItems = styled.ul<MobileMenuContainerProps>`
  z-index: 10;
  display: block;
  position: absolute;
  z-index: 1;
  top: 3.52941em;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 664px;
  margin: 0 auto;
  padding: 32px 22px 8px;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 1s;
  transition: visibility 0s linear 1s;
  list-style: none;
`;
const MobileMenuItem = styled.li<MobileMenuContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: ${(props) =>
    props.hideBorder
      ? "none"
      : `1px solid ${props.theme.colors.fontWhite + "55"}`};
  border-top: ${(props) =>
    props.hideBorder
      ? "none"
      : props.borderTop && `1px solid ${props.theme.colors.fontWhite + "55"}`};
  transition: opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s;
  opacity: ${(props) => (props.open ? "1" : "0")};
  a,
  span {
    font-size: 18px;
    font-weight: 500;
    padding: ${(props) => (props.noPadding ? "0" : "16px 0px")};
    color: ${(props) => props.theme.colors.fontWhite};
    button {
      width: 100%;
    }
  }
`;
const MobileMenuSpacer = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 48px;
`;
interface MobileMenuProps {
  open: boolean;
  isLoggedIn: boolean;
  isLoggedInTalent: boolean;
  handleSignOut: any;
}

export const MobileMenu: React.FunctionComponent<MobileMenuProps> = ({
  open,
  isLoggedIn,
  isLoggedInTalent,
  handleSignOut,
}) => {
  const handleClose = () => {
    if (document != null) {
      document
        .getElementsByTagName("html")[0]
        ?.classList.remove("sme-no-scroll");
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("sme-no-scroll");
    }
  };

  return (
    <MobileMenuContainer open={open} className={open === true && "isOpen"}>
      <MobileMenuItems open={open}>
        <MobileMenuItem open={open}>
          <StyledLink href="/speed-recruiting" onClick={() => handleClose()}>
            Speed-Recruiting
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuItem open={open}>
          <StyledLink href="/talentpool" onClick={() => handleClose()}>
            Talentpool
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuItem open={open}>
          <StyledLink href="/jobs" onClick={() => handleClose()}>
            Jobbörse
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuItem open={open}>
          <StyledLink href="/companies" onClick={() => handleClose()}>
            Unternehmen entdecken
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuItem open={open}>
          <StyledLink href="/blog" onClick={() => handleClose()}>
            Blog
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuSpacer />
        <MobileMenuItem open={open}>
          <StyledLink
            href="/recruiting-loesungen"
            onClick={() => handleClose()}
          >
            Für Arbeitgeber
          </StyledLink>
        </MobileMenuItem>
        <MobileMenuSpacer />
        {isLoggedIn ? (
          <>
            <MobileMenuItem open={open} hideBorder>
              <StyledLink href="/app/company/dashboard">
                <Button
                  primary
                  size={ButtonSizes.m}
                  onClick={() => handleClose()}
                >
                  Unternehmensprofil
                </Button>
              </StyledLink>
            </MobileMenuItem>
            <MobileMenuItem open={open} hideBorder>
              <Button
                primary={false}
                size={ButtonSizes.m}
                onClick={() => {
                  handleSignOut();
                  handleClose();
                }}
              >
                Ausloggen
              </Button>
            </MobileMenuItem>
          </>
        ) : isLoggedInTalent ? (
          <>
            <MobileMenuItem open={open} hideBorder>
              <StyledLink href="/app/talent/profile/edit">
                <Button
                  primary
                  size={ButtonSizes.m}
                  onClick={() => handleClose()}
                >
                  Dein Profil
                </Button>
              </StyledLink>
            </MobileMenuItem>
            <MobileMenuItem open={open} hideBorder>
              <Button
                primary={false}
                size={ButtonSizes.m}
                onClick={() => {
                  handleSignOut();
                  handleClose();
                }}
              >
                Ausloggen
              </Button>
            </MobileMenuItem>
          </>
        ) : (
          <>
            <MobileMenuItem open={open} noPadding hideBorder>
              <StyledLink href="/login">
                <Button
                  primary
                  size={ButtonSizes.m}
                  onClick={() => handleClose()}
                >
                  Login
                </Button>
              </StyledLink>
            </MobileMenuItem>
            <MobileMenuItem open={open} hideBorder>
              <StyledLink href="/register">
                <Button
                  primary={false}
                  size={ButtonSizes.m}
                  onClick={() => handleClose()}
                >
                  Registrieren
                </Button>
              </StyledLink>
            </MobileMenuItem>
          </>
        )}
      </MobileMenuItems>
    </MobileMenuContainer>
  );
};
