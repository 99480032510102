import styled from 'styled-components';
import { device } from "./device";

interface ContainerProps {
  readonly centered?: boolean;
  readonly backgroundColor?: string;
  readonly navbar?: boolean;
  readonly height?: string;
  readonly overflow?: string;
}

export const Container = styled.section<ContainerProps>`
    display: grid;
    grid-template-columns: [full-start] minmax(20px,1fr) [standard-start] 0 [narrow-start] minmax(1rem,67.5rem) [narrow-end] 0 [standard-end] minmax(20px,1fr) [full-end];
    padding: 0;
    height: ${(props) => (props.height ? props.height : 'unset')};
    overflow: ${(props) => (props.overflow ? props.overflow : 'unset')};
    position: relative;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'unset')};
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
    ${(props) => (props.navbar && `
      border-bottom: 1px solid #e8e9ea;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    `)};
    @media (min-width: 50rem) {
       grid-template-columns: [full-start] minmax(2rem,1fr) [standard-start] 3.75rem [narrow-start] minmax(1rem,67.5rem) [narrow-end] 3.75rem [standard-end] minmax(2rem,1fr) [full-end];
    }
`;

export const ContainerContentNarrow = styled.div`
  grid-column: narrow;
`;
export const ContainerContentFullButNarrowIfDesktop = styled.div`
  grid-column: full;

  @media ${device.tablet} {
    grid-column: narrow;
  }
`;
export const ContainerContentStandard = styled.div`
  grid-column: standard;
  position: relative;
`;
export const ContainerContentFull = styled.div`
  grid-column: full;
  position: relative;
`;

export const ContainerContentFullIfDesktop = styled.div`
  grid-column: standard;

  @media ${device.laptop} {
    grid-column: full;
  }
`;

export const TextContainerMain = styled.div`
    width: 970px;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    margin-bottom: 64px;
`;

export const TablePromoContainer = styled.div`
    max-width: 980px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;

    p {
        font-size: 16px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.fontBlack};
        text-align: left;
    }
`;