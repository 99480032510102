import { Auth } from "aws-amplify";
import Router from "next/router";
import { useEffect, useState } from "react";
import Alert from "../../../../shared/components/global/alert";
import { isUserLoggedIn } from "../../../../shared/utils/auth";
import { reset } from "../../redux/companySlice";
import { useAppDispatch } from "../../redux/hooks";
import { resetTalent } from "../../redux/talentSlice";
import Footer from "./footer";
import { MobileMenu } from "./navigation/MobileMenu";
import Navbar from "./navigation/Navbar";
import NavbarBackend from "./navigation/NavbarBackend";

interface LayoutProps {
  preview: boolean;
  children: any;
  headerAbsolute?: boolean;
  headerFullscreen?: boolean;
  headerBackend?: boolean;
  hideMenu?: boolean;
  inverted?: boolean;
  showCallToAction?: boolean;
  noScroll?: boolean;
}

export default function Layout({
  preview,
  children,
  headerAbsolute,
  headerFullscreen,
  headerBackend,
  hideMenu,
  inverted,
  showCallToAction,
  noScroll,
}: LayoutProps) {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedInTalent, setIsLoggedInTalent] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchSetIsLoggedIn = async () => {
      setIsLoggedIn(await isUserLoggedIn("Agentur"));
      setIsLoggedInTalent(await isUserLoggedIn("Talente"));
    };

    fetchSetIsLoggedIn();
  }, [isLoggedIn]);

  const handleToggle = () => {
    if (document != null) {
      if (!sidebarOpened) {
        document
          .getElementsByTagName("html")[0]
          ?.classList.add("sme-no-scroll");
        document
          .getElementsByTagName("body")[0]
          ?.classList.add("sme-no-scroll");
      } else {
        document
          .getElementsByTagName("html")[0]
          ?.classList.remove("sme-no-scroll");
        document
          .getElementsByTagName("body")[0]
          ?.classList.remove("sme-no-scroll");
      }
    }
    setSidebarOpened(!sidebarOpened);
  };

  const handleSignOut = () => {
    if (document != null) {
      document
        .getElementsByTagName("html")[0]
        ?.classList.remove("sme-no-scroll");
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("sme-no-scroll");
    }
    Auth.signOut().then(() => {
      setIsLoggedIn(false);
      Router.push("/").then(() => {
        dispatch(reset());
        dispatch(resetTalent());
        if (typeof window != "undefined" && window.location.pathname === "/") {
          window.location.reload();
        }
      });
    });
  };

  return (
    <>
      <div
        className={
          noScroll === true ? "flex-container-noscroll" : "flex-container"
        }
      >
        {headerBackend ? (
          <NavbarBackend
            headerAbsolute={headerAbsolute}
            darkmode={inverted || sidebarOpened}
            toggleSidebar={() => handleToggle()}
            sidebarOpened={sidebarOpened}
            isLoggedIn={isLoggedIn}
            handleSignOut={() => handleSignOut()}
          />
        ) : (
          <Navbar
            headerAbsolute={headerAbsolute}
            headerFullscreen={headerFullscreen}
            hideMenu={hideMenu}
            darkmode={inverted || sidebarOpened}
            toggleSidebar={() => handleToggle()}
            sidebarOpened={sidebarOpened}
            isLoggedIn={isLoggedIn}
            handleSignOut={() => handleSignOut()}
          />
        )}

        <Alert preview={preview} />
        <MobileMenu
          open={sidebarOpened}
          isLoggedIn={isLoggedIn}
          isLoggedInTalent={isLoggedInTalent}
          handleSignOut={() => handleSignOut()}
        />
        <main role="main">{children}</main>
      </div>
      <Footer showCallToAction={showCallToAction} />
    </>
  );
}
