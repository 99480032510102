import { Container, ContainerContentStandard } from '../../styles/container'
import StyledLink from './StyledLink'

export default function Alert({ preview }) {
  return (
    <div>
      <Container>
        <ContainerContentStandard>
          <div>
            {preview && (
              <>
                This is a page preview.
                <StyledLink
                  href="/api/exit-preview"
                >
                  Click here
                </StyledLink>
                to exit preview mode.
              </>
            )}
          </div>
        </ContainerContentStandard>
      </Container>
    </div>
  )
}
