import styled from "styled-components";
import StyledLink from "../../../../shared/components/global/StyledLink";
import { Button, ButtonSizes } from "../../../../shared/styles/button";
import {
  Container,
  ContainerContentStandard,
} from "../../../../shared/styles/container";
import { device } from "../../../../shared/styles/device";
import { Logo } from "../../../../shared/styles/logo";
import TalentpoolLogo from "/public/svg/Logo.svg";
import FacebookLogo from "/public/svg/social/fb.svg";
import InstagramLogo from "/public/svg/social/ig.svg";
import LinkedInLogo from "/public/svg/social/linked.svg";

interface FooterContainerProps {
  showCallToAction?: boolean;
}

const FooterContainer = styled.div<FooterContainerProps>`
  background-color: ${(props) =>
    props.showCallToAction
      ? props.theme.colors.primary
      : props.theme.colors.fontWhite};
  border-radius: 0px ${(props) => props.theme.borderRadiusSectionMobile} 0px 0px;
  width: 100%;

  @media ${device.tablet} {
    border-radius: 0px ${(props) => props.theme.borderRadiusSection} 0px 0px;
  }
`;

const FooterCallToAction = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;

  h2 {
    color: ${(props) => props.theme.colors.fontBlack};
    font-size: 22px;
    margin-bottom: 24px;
    margin-top: 10px;
    text-align: left;
  }
  p {
    color: ${(props) => props.theme.colors.fontBlack};
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media ${device.tablet} {
    align-items: center;
    padding-bottom: 80px;
    padding-top: 80px;
    h2 {
      margin-bottom: 32px;
      margin-top: 0px;
      font-size: 32px;
      text-align: center;
    }
    p {
      margin-bottom: 30px;
      font-size: 22px;
      max-width: 80%;
    }
  }
`;

const FooterContentContainer = styled.div<FooterContainerProps>`
  background-color: ${(props) => props.theme.colors.primaryBg};
  border-radius: 0px ${(props) => props.theme.borderRadiusSectionMobile} 0px 0px;
  margin-right: ${(props) => (props.showCallToAction ? "40px" : "0px")};

  h3,
  p,
  a,
  span,
  svg {
    color: ${(props) => props.theme.colors.fontWhite};
    fill: ${(props) => props.theme.colors.fontWhite};
  }

  @media ${device.tablet} {
    border-radius: 0px ${(props) => props.theme.borderRadiusSection} 0px 0px;
    margin-right: ${(props) => (props.showCallToAction ? "80px" : "0px")};
  }
`;
const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 0px;
  row-gap: 30px;

  a {
    display: flex;
    width: fit-content;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .footer-item-list {
    p {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .h3-second {
      margin-top: 30px;
    }
  }
  @media ${device.tablet} {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    column-gap: 16px;
    padding: 64px 0px;
  }
`;
const FooterBottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 12px 0px;

  p {
    margin: 0;
  }

  span {
    margin: 0 0.5rem;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 24px;
  }
`;
const FooterBottomBarLinks = styled.div`
  text-align: left;

  @media ${device.tablet} {
    text-align: right;
  }
`;
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;

  svg {
    height: 24px;
    width: 24px;
  }

  @media ${device.tablet} {
    text-align: right;
  }
`;

export default function Footer({ showCallToAction }) {
  return (
    <FooterContainer showCallToAction={showCallToAction}>
      {showCallToAction && (
        <Container>
          <ContainerContentStandard>
            <FooterCallToAction>
              <h2>
                Bereit 10 Top-Agenturen in nur einer Stunde kennenzulernen und
                direkt spannende Jobangebote zu erhalten?
              </h2>
              <StyledLink href="/register">
                <Button primary size={ButtonSizes.l}>
                  Jetzt kostenlos registrieren
                </Button>
              </StyledLink>
            </FooterCallToAction>
          </ContainerContentStandard>
        </Container>
      )}
      <FooterContentContainer showCallToAction={showCallToAction}>
        <Container>
          <ContainerContentStandard>
            <FooterContent>
              <div className="footer-column">
                <Logo darkmode>
                  <StyledLink href="/" arialabel="STELL-MICH-EIN Logo">
                    <TalentpoolLogo />
                  </StyledLink>
                </Logo>
                <p>
                  STELL-MICH-EIN – Dein Karriere-Boost in der
                  Kommunikationsbranche. <br />
                  Bist du auf der Suche nach einem neuen Job? <br />
                  Dann wirst du hier fündig. <br /> <br />
                </p>
                <SocialContainer>
                  <a
                    href="https://www.facebook.com/stellmichein/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FacebookLogo />
                  </a>
                  <a
                    href="https://www.instagram.com/stellmichein/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <InstagramLogo />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/stell-mich-ein/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <LinkedInLogo />
                  </a>
                </SocialContainer>
              </div>
              <div className="footer-item-list">
                <h3>Events</h3>
                <StyledLink href="/speed-recruiting">
                  <p>Speed-Recruiting</p>
                </StyledLink>
                <h3 className="h3-second">Talentpool</h3>
                <StyledLink href="/talentpool">
                  <p>Talents</p>
                </StyledLink>
                <StyledLink href="/talentpool/arbeitgeber">
                  <p>Für Arbeitgeber</p>
                </StyledLink>
              </div>
              <div className="footer-item-list">
                <h3>Jobbörse</h3>
                <StyledLink href="/jobs" aria-label="Jobbörse">
                  <p>Jobbörse</p>
                </StyledLink>
                <StyledLink href="/jobs/company" aria-label="Jobbörse">
                  <p>Jetzt Job ausschreiben</p>
                </StyledLink>
              </div>
              <div className="footer-item-list">
                <h3>Über STELL-MICH-EIN</h3>
                <StyledLink href="/blog">
                  <p>Blog</p>
                </StyledLink>
                <StyledLink href="/presse">
                  <p>Presse</p>
                </StyledLink>
                <StyledLink href="/ueber-uns">
                  <p>Über uns</p>
                </StyledLink>
                <StyledLink href="/kontakt">
                  <p>Kontakt</p>
                </StyledLink>
              </div>
            </FooterContent>
            <FooterBottomBar>
              <p>© STELL-MICH-EIN 2024</p>
              <FooterBottomBarLinks>
                <StyledLink href="/datenschutz">Datenschutz</StyledLink>
                <span>|</span>
                <StyledLink href="/impressum">Impressum</StyledLink>
              </FooterBottomBarLinks>
            </FooterBottomBar>
          </ContainerContentStandard>
        </Container>
      </FooterContentContainer>
    </FooterContainer>
  );
}
