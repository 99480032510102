import { Auth } from "@aws-amplify/auth";

export const isUserLoggedIn = async (userGroup) => {
  let isUser = false;
  try {
    const user = await Auth.currentAuthenticatedUser();
    isUser = isUserPartOfGroup(user, userGroup);
  } catch (error) {
    // hide error from console
  }

  return isUser;
};

export const isUserPartOfGroup = (user: any, group: string) => {
  const usergroups =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"];
  if (usergroups != null && usergroups?.includes(group)) {
    return true;
  }
  return false;
};

export const setUserRedirectFromSocial = () =>
  (window.localStorage.fromSocial = JSON.stringify(true));
